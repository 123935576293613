import {
  type Dictionary,
  filter,
  groupBy,
  includes,
  mapKeys,
  values,
} from "lodash";
import { defineStore } from "pinia";

import db from "@/firebase/db";
import { useSkillGlobalStore } from "@/pinia/collections";
import { useAuthentificationStore } from "@/pinia/user";
import DbHelper from "@/tscript/dbHelper";
//* Stores
//* Libs
import type { IKeyword } from "@/tscript/interfaces";
import type { Mercateam } from "@/tscript/mercateam";

import { getRightWhere } from "@/tscript/utils";
import { createDictionary } from "@/tscript/utils/dictionary";

const dbHelper = new DbHelper(db);

export const useKeywordStore = defineStore({
  actions: {
    async loadKeyword() {
      const user = useAuthentificationStore();
      if (user.data?.client_id) {
        const arrayWhere = getRightWhere(user.data);
        const categoriesList =
          await dbHelper.getAllDataAndSubCollectionsWithWhereArray(
            "categories",
            "keywords",
            arrayWhere,
          );
        const keywordsList: IKeyword[] = [];
        for (const cat of categoriesList) {
          if (cat?.keywords?.length > 0) {
            for (let i = 0; i < cat?.keywords.length; i++) {
              const keyword: any = cat?.keywords[i];
              keyword.category_id = cat?.id;
              keyword.category_name = cat?.name;
              keyword.site_id = cat?.site_id;
              keywordsList.push(keyword);
            }
          }
        }
        this.documents = keywordsList;
        this.queryFetched = true;
      } else {
        throw new Error("user.data should be load first");
      }
    },
  },
  getters: {
    dictionary(state) {
      if (state.documents) {
        return createDictionary<IKeyword>(state.documents);
      }
      return null;
    },
    keywordsBySite: (state) => {
      return mapKeys(values(groupBy(state.documents, "site_id")), (arr) => {
        return arr[0].site_id;
      });
    },
    processKeywords: (state): IKeyword[] => {
      if (!state.documents) return [];
      return filter(
        state.documents,
        (keyword: IKeyword) => keyword.is_process === true,
      );
    },
    skillsByProcessCollection: (): {
      processId: Mercateam.KeywordID;
      processName: string;
      skills: Mercateam.SkillID[];
    }[] => {
      const keywordStore = useKeywordStore();
      const skillGlobalStore = useSkillGlobalStore();
      const result: {
        processId: Mercateam.KeywordID;
        processName: string;
        skills: Mercateam.SkillID[];
      }[] = [];
      for (const processKeyword of keywordStore.processKeywords) {
        const skillsByProcess: Mercateam.SkillID[] = [];
        const skillsActives = skillGlobalStore.actives || [];
        for (const skillActive of skillsActives) {
          if (includes(skillActive.keywords_temp, processKeyword.id)) {
            skillsByProcess.push(skillActive.id);
          }
        }
        result.push({
          processId: processKeyword.id,
          processName: processKeyword.name,
          skills: skillsByProcess,
        });
      }
      return result;
    },
    skillsByProcessDictionary: (): Dictionary<Mercateam.SkillID[]> | null => {
      const keywordStore = useKeywordStore();
      const skillGlobalStore = useSkillGlobalStore();
      const result: Dictionary<string[]> = {};
      for (const processKeyword of keywordStore.processKeywords) {
        const skillsByProcess: Mercateam.SkillID[] = [];
        const skillsActives = skillGlobalStore.actives || [];
        for (const skillActive of skillsActives) {
          if (includes(skillActive.keywords_temp, processKeyword.id)) {
            skillsByProcess.push(skillActive.id);
          }
        }
        result[processKeyword.id] = skillsByProcess;
      }
      return result;
    },
  },
  id: "keywordStore",
  state: () => ({
    documents: null as IKeyword[] | null,
    queryFetched: false,
  }),
});
