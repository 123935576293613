import { z } from "zod";

import type { ClientID, SiteID, TeamID } from "@/tscript/mercateam";

import { allSectionNames } from "./utils/sections";

export interface GPricingAutocomplete {
  id: string;
  name: string;
}

const ZRight = z.object({
  id: z.string(),
  name: z.string(),
});

export const ERole = z.enum(["ADMIN", "CUSTOMER_SUCCESS", "USER"]);
export const EPricingPlan = z.enum([
  "SkillStarter",
  "SkillTrainer",
  "SkillPlanner",
  "SkillMaster",
]);
export const ESectionName = z.enum(allSectionNames);
export const ESectionRightValue = z.enum([
  "no_access",
  "total_access",
  "limited_access",
]);

export const ESectionRightRow = z.enum(["readonly", "edit"]);
export const ESectionAction = z.enum([
  "read",
  "create",
  "save",
  "update",
  "archive",
  "delete",
  "anyAction",
]);

const IPricing = z.object({
  createdAt: z.date(),
  id: z.string(),
  name: z.string(),
  section_ids: z.array(z.string()),
  sections: z.array(z.any()).optional(),
  site_ids: z.array(z.string()),
  status: z.string().optional(),
  updatedAt: z.date(),
});

const LSectionValues = z.object({
  createdAt: z.date(),
  edit: ESectionRightValue,
  id: z.string(),
  readonly: ESectionRightValue,
  status: z.string().optional(),
  updatedAt: z.date(),
});

const Section = z.object({
  available_options_edit: z.array(ESectionRightValue),
  available_options_readonly: z.array(ESectionRightValue),
  createdAt: z.date(),
  default_value_edit: ESectionRightValue,
  default_value_readonly: ESectionRightValue,
  id: z.string(),
  pricing: IPricing,
  pricing_id: z.string(),
  section_name: ESectionName,
  section_order: z.number(),
  section_translate_name: z.string(),
  status: z.string().optional(),
  updatedAt: z.date(),
});

const LSection = z.object({
  createdAt: z.date(),
  id: z.string(),
  section: Section,
  status: z.string().optional(),
  updatedAt: z.date(),
  userRights_id: z.string().nullish(),
  values: LSectionValues,
});

const IUserRights = z.object({
  client_id: z.string(),
  createdAt: z.date(),
  default: z.boolean(),
  id: z.string(),
  is_template: z.boolean(),
  link_sections: z.array(LSection),
  name: z.string(),
  site_id: z.string(),
  status: z.string().optional(),
  updatedAt: z.date(),
  user_ids: z.array(z.string()).optional(),
  users: z.array(z.any()),
});

export type TAddOnsSectionName =
  | "employee_performance_reviews"
  | "performance_reviews_campaign"
  | "planning_auto"
  | "team_versatility"
  | "workforce_planning"
  | "workforce_planning_uimm";

export type GRightAutocomplete = z.infer<typeof ZRight>;
export type ERole = z.infer<typeof ERole>;
export type EPricingPlan = z.infer<typeof EPricingPlan>;
export type ESectionName = z.infer<typeof ESectionName>;
export type ESectionRightRow = z.infer<typeof ESectionRightRow>;
export type ESectionRightValue = z.infer<typeof ESectionRightValue>;
export type ESectionAction = z.infer<typeof ESectionAction>;

export type IPricing = z.infer<typeof IPricing>;
export type IUserRights = z.infer<typeof IUserRights>;
export type Section = z.infer<typeof Section>;
export type LSection = z.infer<typeof LSection>;
export type LSectionValues = z.infer<typeof LSectionValues>;

export type InputIUserRights = Omit<
  z.infer<typeof IUserRights>,
  "createdAt" | "id" | "updatedAt"
>;

export type InputLSectionValues = Omit<
  z.infer<typeof LSectionValues>,
  "createdAt" | "id" | "updatedAt"
>;

export interface ESectionScope {
  client_id?: ClientID;
  site_id?: SiteID;
  team_id?: TeamID;
  team_ids?: TeamID[];
}
