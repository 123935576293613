import type { TranslateResult } from "vue-i18n";

import {
  addDays,
  addMonths,
  addWeeks,
  addYears,
  getDate,
  getISOWeek,
  getWeek,
  subDays,
  subMonths,
  subYears,
} from "date-fns";
import { fr } from "date-fns/locale";
import { gql } from "graphql-tag";
import {
  chain,
  type Dictionary,
  every,
  filter,
  forEach,
  groupBy,
  includes,
  map,
  orderBy,
  range,
  toString,
  uniq,
} from "lodash";
import { defineStore } from "pinia";

import type {
  DataPlanning,
  FastEmployeeCard,
  IContract,
  ICurrentWeekHeader,
  IDropdownOptions,
  IEmployee,
  IEmployeeLoan,
  IPosition,
  IPositionLevel,
  IShift,
} from "@/tscript/interfaces";
import type { LAbsence, LPlanning, LShift } from "@/tscript/links_employee";
import type { EmployeeID, TeamID } from "@/tscript/mercateam";

import i18n from "@/i18n";
import * as baseCollection from "@/pinia/collections";
import { usePlanningDisplayStore } from "@/pinia/features/planning-display";
import { useAuthentificationStore } from "@/pinia/user";
import { segmentTriggerEvent } from "@/tscript/analytics";
import { DateUtils } from "@/tscript/utils/date";
import { createDictionary } from "@/tscript/utils/dictionary";
import { apolloProvider } from "@/vue-apollo";

import { useTeamDataStore } from "../team";

const isEmployeeLoan = (
  value: IEmployee | IEmployeeLoan,
): value is IEmployeeLoan => {
  return "team_loan" in value;
};

export const usePlanningStore = defineStore({
  actions: {
    async getAvailableSlots(teamIDs: TeamID[]): Promise<any> {
      const PlanningDisplayStore = usePlanningDisplayStore();
      const result = await apolloProvider.defaultClient.mutate({
        fetchPolicy: "no-cache",
        mutation: gql`
          mutation AvailableSlots(
            $teamIds: [String!]!
            $startDate: DateTimeISO!
            $endDate: DateTimeISO!
          ) {
            AvailableSlots(
              team_ids: $teamIds
              start_date: $startDate
              end_date: $endDate
            ) {
              availableEmployees
              availablePositions
              availableSubPositions
            }
          }
        `,
        variables: {
          endDate: this.endISO,
          startDate: this.startISO,
          teamIds: teamIDs,
        },
      });
      PlanningDisplayStore.availableSlotsFilterResult =
        result.data.AvailableSlots[0];
    },
    reset() {
      baseCollection.useEmployeePlanningStore().unsubscribe();
      baseCollection.useEmployeeShiftStore().unsubscribe();
      baseCollection.useEmployeeAbsencesStore().unsubscribe();
      // baseCollection.usePositionsClosedStore().unsubscribe();
      this.$state = {
        alignPositions: false,
        apolloCacheVariables: {
          cellAbsences: null as any,
          cellResults: null as any,
          cellShifts: null as any,
          planningData: null as any,
          positionCloseds: null as any,
        },
        card_score: {
          absent_score: 0,
          already_assigned_score: 0,
          restriction_score: 0,
          shift_score: 0,
          skills_clearances_score: 0,
          training_score: 0,
          without_shift_score: 0,
        },
        compactTable: false,
        date: new Date(),
        loading: false,
        onViewDay: false,
        refetch: Date.now(),
        reLoad: Date.now(),
        search: null as
          | ({ name: string; search_type: string } & IContract)
          | ({ search_type: string } & FastEmployeeCard)
          | ({ search_type: string } & IPosition)
          | ({ search_type: string } & IPositionLevel)
          | null,
        selectedViewLevel: "week",
        shiftSelected: {
          icon: "mdi-view-grid",
          id: "",
          name: i18n.t("planning.all_shifts"),
        } as IDropdownOptions,
      };
    },
    updateDate(type: "day" | "month" | "year", value: number) {
      segmentTriggerEvent("navigate_planning");
      if (value > 0) {
        switch (type) {
          case "day":
            this.date = addDays(this.date, value);
            break;
          case "month":
            this.date = addMonths(this.date, value);
            break;
          case "year":
            this.date = addYears(this.date, value);
            break;
          default:
            break;
        }
      } else {
        switch (type) {
          case "day":
            this.date = subDays(this.date, -value);
            break;
          case "month":
            this.date = subMonths(this.date, -value);
            break;
          case "year":
            this.date = subYears(this.date, -value);
            break;
          default:
            break;
        }
      }
      // const teamId = TeamDataStore.team.id;
      // if (teamId) await this.getAvailableSlots([teamId]);
    },
    updateDateFull(date: Date) {
      this.date = date;
    },
  },
  getters: {
    currentDayHeader(): ICurrentWeekHeader[] {
      const strings = [
        i18n.t("global.days_of_week.complete.sunday"),
        i18n.t("global.days_of_week.complete.monday"),
        i18n.t("global.days_of_week.complete.tuesday"),
        i18n.t("global.days_of_week.complete.wednesday"),
        i18n.t("global.days_of_week.complete.thursday"),
        i18n.t("global.days_of_week.complete.friday"),
        i18n.t("global.days_of_week.complete.saturday"),
      ];
      const startISO = new Date(
        Date.UTC(this.selected.year, this.selected.month, this.selected.day),
      );

      const currentDayInWeek = startISO.getUTCDay();
      const currentDayHeader = [
        {
          date: startISO,
          day: this.selected.day,
          day_week: currentDayInWeek,
          isWeekEnd: currentDayInWeek === 0 || currentDayInWeek === 6,
          month: this.selected.month,
          string: strings[currentDayInWeek],
          week: getWeek(startISO, { locale: fr, weekStartsOn: 1 }),
          year: this.selected.year,
        },
      ];

      return currentDayHeader;
    },
    currentMonthHeader(): ICurrentWeekHeader[] {
      const strings = [
        i18n.t("global.days_of_week.abbr.sunday"),
        i18n.t("global.days_of_week.abbr.monday"),
        i18n.t("global.days_of_week.abbr.tuesday"),
        i18n.t("global.days_of_week.abbr.wednesday"),
        i18n.t("global.days_of_week.abbr.thursday"),
        i18n.t("global.days_of_week.abbr.friday"),
        i18n.t("global.days_of_week.abbr.saturday"),
      ];
      const date = new Date(this.selected.year, this.selected.month, 1);
      const days: ICurrentWeekHeader[] = [];
      while (date.getMonth() === this.selected.month) {
        const d: Date = new Date(date);
        const currentDayInMonth = d.getDate();
        const currentDayInWeek = d.getDay();
        days.push({
          date: d,
          day: currentDayInMonth,
          day_week: currentDayInWeek,
          isWeekEnd: currentDayInWeek === 0 || currentDayInWeek === 6,
          month: d.getMonth() + 1,
          string: strings[currentDayInWeek],
          week: getWeek(d, { locale: fr, weekStartsOn: 1 }),
          year: d.getFullYear(),
        });
        date.setDate(currentDayInMonth + 1);
      }
      return days;
    },
    currentWeekDates(): Date[] {
      return map(this.currentWeekHeader, (w) => w.date);
    },
    currentWeekHeader(): ICurrentWeekHeader[] {
      const strings = [
        i18n.t("global.days_of_week.complete.monday"),
        i18n.t("global.days_of_week.complete.tuesday"),
        i18n.t("global.days_of_week.complete.wednesday"),
        i18n.t("global.days_of_week.complete.thursday"),
        i18n.t("global.days_of_week.complete.friday"),
        i18n.t("global.days_of_week.complete.saturday"),
        i18n.t("global.days_of_week.complete.sunday"),
      ];
      const startISO = new Date(
        Date.UTC(
          this.startISO.getUTCFullYear(),
          this.startISO.getUTCMonth(),
          this.startISO.getUTCDate(),
        ),
      );
      const currentWeekHeader: ICurrentWeekHeader[] = [];
      for (let index = 0; index < 7; index++) {
        const currentDay = addDays(startISO, index);
        const currentDayInMonth = currentDay.getDate();
        const currentDayInWeek = currentDay.getDay();
        const currentDayFormated = {
          date: currentDay,
          day: currentDayInMonth,
          day_week: currentDayInWeek,
          isWeekEnd: currentDayInWeek === 0 || currentDayInWeek === 6,
          month: currentDay.getMonth() + 1,
          string: strings[index],
          week: getWeek(currentDay, { locale: fr, weekStartsOn: 1 }),
          year: currentDay.getFullYear(),
        };
        currentWeekHeader.push(currentDayFormated);
      }

      return currentWeekHeader;
    },
    dataAbsencesPlanning(): LAbsence[] {
      const EmployeeAbsencesStore = baseCollection.useEmployeeAbsencesStore();
      return EmployeeAbsencesStore.actives || [];
    },
    dataPlanning: (state): DataPlanning[] => {
      // Guard for blinking
      const SubpositionStore = baseCollection.useSubpositionStore();
      const PositionStore = baseCollection.usePositionStore();
      const EmployeeGlobal = baseCollection.useEmployeeGlobalStore();
      const RestrictionStore = baseCollection.useEmployeeRestrictionStore();
      const AbsencesStore = baseCollection.useEmployeeAbsencesStore();

      // Import Externals Stores
      const EmployeePlanningStore = baseCollection.useEmployeePlanningStore();
      const EmployeeSkillStore = baseCollection.useEmployeeSkillStore();
      const EmployeeClearanceStore = baseCollection.useEmployeeClearanceStore();
      const TeamDataStore = useTeamDataStore();

      if (
        (!EmployeePlanningStore.hasSubcribed &&
          EmployeePlanningStore.hasRealtime) ||
          (!EmployeeSkillStore.hasSubcribed && EmployeeSkillStore.hasRealtime) ||
          (!EmployeeClearanceStore.hasSubcribed &&
            EmployeeClearanceStore.hasRealtime) ||
            (!PositionStore.hasSubcribed && PositionStore.hasRealtime) ||
            (!SubpositionStore.hasSubcribed && SubpositionStore.hasRealtime) ||
            (!EmployeeGlobal.hasSubcribed && EmployeeGlobal.hasRealtime) ||
            (!RestrictionStore.hasSubcribed && RestrictionStore.hasRealtime) ||
            (!AbsencesStore.hasSubcribed && AbsencesStore.hasRealtime)
      ) {
        return [];
      }
      const positionsIDs = chain(TeamDataStore.order_positions_and_subpositions)
        .filter((p) => {
          const sub = p as IPositionLevel;
          return !sub.position_parent_id;
        })
        .map((p) => p.id)
        .value();
      const subPositionsIDs = chain(
        TeamDataStore.order_positions_and_subpositions,
      )
        .filter((p) => {
          const sub = p as IPositionLevel;
          return !!sub.position_parent_id;
        })
        .map((p) => p.id)
        .value();
      const employeesIDs = map(TeamDataStore.employees, (e) => e.id);
      const returnDataPlanning = chain(EmployeePlanningStore.actives)
        .map((planning) => {
          const date = DateUtils.timestampToDate(planning.date);
          return {
            ...planning,
            date,
            day: Number(planning.day),
            day_nb: date.getDay(),
            end: toString(planning.end).length > 0 ? planning.end : null,
            month: Number(planning.month),
            // date: new Date(`${planning.day}/`${planning.month}/`${planning.year}`),
            shift: planning.shift_id,
            start: toString(planning.start).length > 0 ? planning.start : null,
            subposition_id: planning.subposition_id
              ? planning.subposition_id
              : null,
            week: Number(planning.week),
            year: Number(planning.year),
          };
        })
        .compact()
        .filter((el) => {
          return includes(employeesIDs, el.employee_id);
        })
        .filter((el: any) => {
          if (state.shiftSelected?.id) {
            return el.shift === state.shiftSelected.id;
          }
          return el;
        })
        .filter((el: any) => {
          if (el.subposition_id) {
            return subPositionsIDs.includes(el.subposition_id);
          }
          return positionsIDs.includes(el.position_id);
        })
        .filter((el: any) => {
          const term:
            | ({ name: string; search_type: string } & IContract)
            | ({
              search_type: string;
            } & FastEmployeeCard)
            | ({
              search_type: string;
            } & IPosition)
            | ({
              search_type: string;
            } & IPositionLevel)
            | null = state.search;
          if (!term) return true;
          if (!term?.id) return true;
          if (term.search_type === "employee") {
            return el.employee_id === term.id;
          }
          if (term.search_type === "skill") {
            let linkEmployeeSkills: any;
            if (EmployeeSkillStore.actives) {
              linkEmployeeSkills = groupBy(
                EmployeeSkillStore.actives,
                "employee_id",
              );
              forEach(linkEmployeeSkills, (value, key) => {
                linkEmployeeSkills[key] = map(value, (el) => {
                  return el.skill_id;
                });
              });
            }
            const res =
              linkEmployeeSkills[el.employee_id] &&
              includes(linkEmployeeSkills[el.employee_id], term.id);
            return res || false;
          }
          if (term.search_type === "clearance") {
            let linkEmployeeClearances: any;
            if (EmployeeClearanceStore.actives) {
              linkEmployeeClearances = groupBy(
                EmployeeClearanceStore.actives,
                "employee_id",
              );
            }
            forEach(linkEmployeeClearances, (value, key) => {
              linkEmployeeClearances[key] = map(value, (el) => {
                return el.clearance_id;
              });
            });
            const res =
              linkEmployeeClearances[el.employee_id] &&
              includes(linkEmployeeClearances[el.employee_id], term.id);
            return res || false;
          }
          if (term.search_type === "position") {
            return el.position_id === term.id;
          }
          if (term.search_type === "subposition") {
            return el.subposition_id === term.id;
          }
          if (term.search_type === "contract") {
            let linkEmployeeContracts: any;
            if (EmployeeGlobal.actives) {
              linkEmployeeContracts = groupBy(EmployeeGlobal.actives, "id");
            }
            forEach(linkEmployeeContracts, (value, key) => {
              linkEmployeeContracts[key] = map(value, (emp) => {
                return emp.contract_id;
              });
            });

            const res =
              linkEmployeeContracts[el.employee_id] &&
              includes(linkEmployeeContracts[el.employee_id], term.id);

            return res || false;
          }
          return true;
        })
        .orderBy("date")
        .value();
      return returnDataPlanning;
    },
    dataShiftsPlanning(): LShift[] {
      const EmployeeShiftStore = baseCollection.useEmployeeShiftStore();
      return EmployeeShiftStore.actives || [];
    },
    dayOfTheWeek(): boolean[] {
      const dayOfTheWeekArray = DateUtils.getDayOfTheWeek(
        DateUtils.convertToDate(this.date),
      );
      return dayOfTheWeekArray;
    },
    dayOfTheWeekNb(): number {
      let res = 1;
      for (let i = 0; i < this.dayOfTheWeek.length; i++) {
        if (this.dayOfTheWeek[i]) res = i;
        break;
      }
      return res;
    },
    dropDownShiftList(): IDropdownOptions[] {
      const result: IDropdownOptions[] = [
        {
          icon: "mdi-view-grid",
          id: "",
          name: i18n.t("planning.all_shifts"),
        },
      ];
      if (this.shifts) {
        result.push(
          ...chain(this.shifts as IShift[])
            .sortBy((s) => s.name)
            .map((shift) => {
              return {
                color: shift.color?.bg ? shift.color.bg : "",
                icon: "",
                id: shift.id,
                name: shift.name,
              };
            })
            .value(),
        );
      }
      return result;
    },
    employeeCards(): FastEmployeeCard[] {
      return filter(this.noFilterEmployeeCards, (item) => {
        const term = this.search;
        if (!term?.id) return true;
        if (term.search_type === "employee") {
          return item.id === term.id;
        }
        if (term.search_type === "skill") {
          const skillFilter = filter(
            item.skills,
            (skill) => skill.skill_id === term.id,
          );
          return skillFilter.length > 0;
        }
        if (term.search_type === "clearance") {
          const clearanceFilter = filter(
            item.clearances,
            (clearance) => clearance.clearance_id === term.id,
          );
          return clearanceFilter.length > 0;
        }
        if (
          term.search_type === "position" ||
          term.search_type === "subposition"
        ) {
          return !!chain(item.planning)
            .filter((o) => {
              return o.position_id === term.id || o.subposition_id === term.id;
            })
            .find((pos) => {
              return (
                Number(pos.week) === this.selected.week &&
                Number(pos.year) === this.selected.year
              );
            })
            .value();
        }
        if (term.search_type === "contract") {
          return item.contract_id === term.id;
        }
        return true;
      });
    },
    employeeCardsdictionary(): Dictionary<any> {
      return createDictionary(this.employeeCards);
    },
    employeesFiltered(state): (IEmployee | IEmployeeLoan)[] {
      const TeamDataStore = useTeamDataStore();
      const EmployeeAbsenceStore = baseCollection.useEmployeeAbsencesStore();
      if (state.compactTable) {
        let temp: EmployeeID[];
        const abscencesPlanning: LAbsence[] =
          EmployeeAbsenceStore.actives || [];
        const dataPlanning = this.dataPlanning;
        const mergePlanning = [...abscencesPlanning, ...dataPlanning];
        if (state.onViewDay) {
          temp = mergePlanning
            .filter((el) => {
              return (
                el.day === this.selected.day && el.year === this.selected.year
              );
            })
            .map((el) => {
              return el.employee_id;
            });
        } else {
          temp = mergePlanning.map((el) => {
            return el.employee_id;
          });
        }
        const res: (IEmployee | IEmployeeLoan)[] = [];
        uniq(temp).map((employeeId: EmployeeID) => {
          const employeeWithPosition =
            TeamDataStore.employeesdictionary[employeeId];
          if (employeeWithPosition) {
            res.push(employeeWithPosition);
          }
        });
        return orderBy(res, "first_name");
      }
      return orderBy(TeamDataStore.employees, "first_name");
    },
    endISO(): Date {
      return addDays(this.startISO, 6);
    },
    nexTenWeeks(state) {
      return map(range(1, 11), (n) => {
        const date = addWeeks(state.date, n);
        return {
          week: getISOWeek(date),
          year: date.getFullYear(),
        };
      });
    },
    noFilterEmployeeCards(): FastEmployeeCard[] {
      const TeamDataStore = useTeamDataStore();
      // LABSENCES (planning)
      const EmployeeAbsenceStore = baseCollection.useEmployeeAbsencesStore();
      // TODO Filter by includes(employeesFiltered) first ?
      const EmployeeTrainingStore = baseCollection.useEmployeeTrainingStore();
      // LSKILLS (team / temporary global)
      const EmployeeSkillStore = baseCollection.useEmployeeSkillStore();
      // LClearances (team / temporary global)
      const EmployeeClearanceStore = baseCollection.useEmployeeClearanceStore();
      // LRestrictions (team / temporary global)
      const EmployeeRestrictionStore =
        baseCollection.useEmployeeRestrictionStore();
      // LShift (planning)
      const EmployeeShiftStore = baseCollection.useEmployeeShiftStore();
      // Fullname format setting
      const AuthentificationStore = useAuthentificationStore();
      const lastname_first = AuthentificationStore.data?.firstname_parameter;

      const EmployeePlanningWithoutTomorrow: LPlanning[] = [];
      const EmployeePlanningWithTomorrow: LPlanning[] = [];

      // if (!EmployeePlanningStore.actives) return [];

      // for (
      //   let index = 0;
      //   index < EmployeePlanningStore.actives.length;
      //   index++
      // ) {
      //   const e = EmployeePlanningStore.actives[index];
      //   if (
      //     idsPositionsSubPosFiltered.includes(e.position_id) ||
      //     idsPositionsSubPosFiltered.includes(e.subposition_id)
      //   ) {
      //     if (!e.isTomorrow) {
      //       EmployeePlanningWithoutTomorrow.push(e);
      //     } else {
      //       EmployeePlanningWithTomorrow.push(e);
      //     }
      //   }
      // }

      return chain(this.employeesFiltered)
        .map((employee: IEmployee | IEmployeeLoan) => {
          const fullname = lastname_first
            ? `${employee.last_name} ${employee.first_name}`
            : `${employee.first_name} ${employee.last_name}`;
          return {
            ...employee,
            absences: filter(EmployeeAbsenceStore.actives, (e) => {
              return e.employee_id === employee.id;
            }),
            clearances: chain(EmployeeClearanceStore.actives)
              .filter((e) => {
                return e.employee_id === employee.id;
              })
              .map((e) => {
                return { clearance_id: e.clearance_id, end_date: e.end_date };
              })
              .value(),
            name: fullname,
            planning: filter(EmployeePlanningWithoutTomorrow, (e) => {
              return e.employee_id === employee.id;
            }),
            planningWithTomorrow: filter(EmployeePlanningWithTomorrow, (e) => {
              return e.employee_id === employee.id;
            }),
            restrictions: chain(EmployeeRestrictionStore.actives)
              .filter((e) => e.employee_id === employee.id)
              .map((e) => e.restriction_id)
              .value(),
            shifts: chain(EmployeeShiftStore.actives)
              .filter((e) => e.employee_id === employee.id)
              .value(),
            skills: chain(EmployeeSkillStore.actives)
              .filter((e) => {
                return e.employee_id === employee.id;
              })
              .map((e) => {
                return { end_date: e.end_date, skill_id: e.skill_id };
              })
              .value(),
            team_loan: isEmployeeLoan(employee)
              ? employee.team_loan === TeamDataStore.team.id
                ? employee.team_id
                : employee.team_loan
              : null,
            team_loan_external: isEmployeeLoan(employee)
              ? employee.team_loan !== TeamDataStore.team.id
              : null,
            team_loan_name: isEmployeeLoan(employee)
              ? employee.team_loan === TeamDataStore.team.id
                ? employee.team_from_name
                : employee.team_loan_name
              : null,
            trainings: filter(
              EmployeeTrainingStore.actives as LTraining[],
              (e) => {
                // Only ongoing trainings.
                return e.employee_id === employee.id && !e.validated;
              },
            ),
          };
        })
        .value();
    },
    planningLoaded(): boolean {
      const EmployeePlanningStore = baseCollection.useEmployeePlanningStore();
      const EmployeeAbsenceStore = baseCollection.useEmployeeAbsencesStore();
      const EmployeeShiftStore = baseCollection.useEmployeeShiftStore();
      if (
        EmployeePlanningStore.hasRealtime &&
        EmployeeAbsenceStore.hasRealtime &&
        EmployeeShiftStore.hasRealtime
      ) {
        return every([
          EmployeePlanningStore.hasSubcribed,
          EmployeeAbsenceStore.hasSubcribed,
          EmployeeShiftStore.hasSubcribed,
        ]);
      }
      return true;
    },
    planningLoadedWithEventListener(): boolean {
      const PlanningStore = usePlanningStore();
      const EmployeePlanningStore = baseCollection.useEmployeePlanningStore();

      if (
        Number(PlanningStore.dataPlanning[0]?.week) ===
        Number(PlanningStore.selected.week) &&
        Number(PlanningStore.dataPlanning[0]?.weekBeginsOnYear) ===
        Number(PlanningStore.selected.weekBeginsOnYear)
      ) {
        return true;
      }
      if (EmployeePlanningStore.documentsScoped) {
        return true;
      }
      return false;
    },
    selected(state): {
      day: number;
      month: number;
      week: number;
      weekBeginsOnYear: number;
      year: number;
    } {
      const isoWeekNumber = getISOWeek(state.date);
      const startOfCurrentWeek = DateUtils.getStartOfWeekByDate(state.date);
      const startOfNextWeek = addDays(startOfCurrentWeek, 7);

      return {
        day: getDate(state.date),
        month: state.date.getMonth(),
        week: isoWeekNumber,
        weekBeginsOnYear: startOfCurrentWeek.getFullYear(),
        year:
          isoWeekNumber === 1
            ? startOfNextWeek.getFullYear()
            : state.date.getFullYear(),
      };
    },
    shifts(): IShift[] {
      const ParameterPlanningStore = baseCollection.useParameterPlanningStore();
      const TeamDataStore = useTeamDataStore();
      return filter(ParameterPlanningStore.list, (pp) => {
        const teamsIDs = map(pp.teams, (t) => t.id);
        return includes(teamsIDs, TeamDataStore.team.id);
      });
    },
    shiftsdictionary(): Dictionary<IShift | undefined> {
      return createDictionary(this.shifts);
    },
    shortDropDownShiftList(): IDropdownOptions[] {
      const result: IDropdownOptions[] = [];
      if (this.shifts) {
        result.push(
          ...chain(this.shifts as IShift[])
            .sortBy((s) => s.name)
            .map((shift) => {
              return {
                color: shift.color?.bg ? shift.color.bg : "",
                icon: "",
                id: shift.id,
                name: shift.name,
              };
            })
            .value(),
        );
      }
      return result;
    },
    startISO(): Date {
      return DateUtils.getStartOfWeekISOBeta(
        this.selected.week,
        this.selected.year,
      );
    },
    switchTimeSubtitleWeek(): string {
      const language = useAuthentificationStore().userLanguage;
      const startISO = DateUtils.getDateOfISOWeek(
        this.selected.week,
        this.selected.weekBeginsOnYear,
      );
      const endISO = addDays(startISO, 6);
      return `${startISO.toLocaleDateString(
        DateUtils.fromUserLanguageToDateTimeFormat(language),
        {
          day: "numeric",
          month: "long",
          year: "2-digit",
        },
      )} - ${endISO.toLocaleDateString(
        DateUtils.fromUserLanguageToDateTimeFormat(language),
        {
          day: "numeric",
          month: "long",
          year: "2-digit",
        },
      )}`;
    },
    switchTimeTitleDay(): TranslateResult {
      const language = useAuthentificationStore().userLanguage;
      const month = this.selected.month;
      const year = this.selected.year;
      return new Date(year, month, this.selected.day).toLocaleDateString(
        DateUtils.fromUserLanguageToDateTimeFormat(language),
        {
          day: "numeric",
          month: "long",
          weekday: "long",
          year: "numeric",
        },
      );
    },
    switchTimeTitleMonth(): TranslateResult {
      const language = useAuthentificationStore().userLanguage;
      const month = this.selected.month;
      const year = this.selected.year;
      return new Date(year, month).toLocaleDateString(
        DateUtils.fromUserLanguageToDateTimeFormat(language),
        {
          month: "long",
          year: "numeric",
        },
      );
    },
    switchTimeTitleWeek(): TranslateResult {
      return i18n.tc("global.week", this.selected.week);
    },
  },
  id: "usePlanning",
  state: () => ({
    alignPositions: false,
    apolloCacheVariables: {
      cellAbsences: null as any,
      cellResults: null as any,
      cellShifts: null as any,
      planningData: null as any,
      positionCloseds: null as any,
    },
    card_score: {
      absent_score: 0,
      already_assigned_score: 0,
      restriction_score: 0,
      shift_score: 0,
      skills_clearances_score: 0,
      training_score: 0,
      without_shift_score: 0,
    },
    compactTable: false,
    date: new Date(),
    loading: false,
    onViewDay: false,
    refetch: Date.now(),
    reLoad: Date.now(),
    search: null as
      | ({ name: string; search_type: string } & IContract)
      | ({ search_type: string } & FastEmployeeCard)
      | ({ search_type: string } & IPosition)
      | ({ search_type: string } & IPositionLevel)
      | null,
    // TODO remove selectedViewLevel && onViewDay
    selectedViewLevel: "week",
    shiftSelected: {
      icon: "mdi-view-grid",
      id: null,
      name: i18n.t("planning.all_shifts"),
    } as IDropdownOptions,
  }),
});
