const advancedSearchChildren = [
  {
    component: () =>
      import(
        "@/components/search/advancedSearch/result/AdvancedSearchResultsGlobal.vue"
      ),
    name: "AdvancedSearchView",
    path: "",
  },
  {
    component: () =>
      import(
        "@/components/search/advancedSearch/result/AdvancedSearchResults.vue"
      ),
    name: "AdvancedSearchResultNestedSkillKeyword",
    path: "nested-skill-keywords",
  },
  {
    component: () =>
      import(
        "@/components/search/advancedSearch/result/AdvancedSearchResults.vue"
      ),
    name: "AdvancedSearchResultNestedSkillClearance",
    path: "nested-clearance-keywords",
  },
  {
    component: () =>
      import(
        "@/components/search/advancedSearch/result/AdvancedSearchResults.vue"
      ),
    name: "AdvancedSearchResultLinkedKeyword",
    path: "skill-linked-keyword",
  },
];

export default advancedSearchChildren;
