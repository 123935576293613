/* eslint-disable */
/* prettier-ignore */
import {RumInitConfiguration} from "@datadog/browser-rum";
import {version} from "../../package.json";
import {ServeConfig} from "./utils";

export const DISPLAY_CALENDAR = true;
export const IS_DEV = false;
export const IS_DEMO = false;
export const IS_STAGING = true;
export const IS_PROD = false;
export const IS_TEST = false;
export const BACKEND_GRAPHQL_URL =  import.meta.env.VITE_BACKEND_URL;
export const BACKEND_CLOUD_RUN_URL = import.meta.env.VITE_BACKEND_URL;

export const FIREBASE_CONFIG = {
  apiKey: "AIzaSyBaA0RZv-xrZ4Aiwk2v6eiVkWE6VNbLXhw",
  authDomain: "mercateam-staging.firebaseapp.com",
  databaseURL: "https://mercateam-staging.firebaseio.com",
  projectId: "mercateam-staging",
  storageBucket: "mercateam-staging.appspot.com",
  messagingSenderId: "359950159463",
  appId: "1:359950159463:web:3acfa08e49fa2900fc8ec2",
  measurementId: "G-89C7LCHBQR"
};

export const config: ServeConfig = {
  useLocalFirestore: false,
  useLocalAuthentification: false,
  useLocalFunctions: false,
  useLocalBackend: false,
  logLevel: "silent",
  mode: "staging",
};

export const DATADOG: RumInitConfiguration = {
  applicationId: "ea9bcf29-5b03-496c-9044-6b4944632ef3",
  clientToken: "pub8c067e90e608629619b8e7191f445188",
  site: "datadoghq.eu",
  service: "mercateam-staging",
  version,
  sessionSampleRate: 100,
  sessionReplaySampleRate: 100,
  trackUserInteractions: true,
  trackResources: true,
  trackLongTasks: true,
  defaultPrivacyLevel: "allow",
};
