export const allSectionNames = [
  "administration",
  "library",
  "performance_reviews_campaign",
  "performance_reviews_models",
  "performance_reviews_analysis",
  "parameters",
  "export",
  "import",
  "dashboard_home",
  "advanced_search",
  "user_profile_informations",
  "user_profile_notifications",
  "content_management",
  "monitoring_cartography",
  "monitoring_jobs",
  "monitoring_goals",
  "questionnaire_management",
  "training_management",
  "training_follow_validate",
  "mass_cartography",
  "planning_auto",
  "team_informations",
  "team_employees",
  "team_versatility",
  "team_overview",
  "team_absences",
  "team_contents",
  "team_planning_management",
  "team_planning_alerts_conflicts",
  "team_planning_alerts_absences",
  "team_planning_badge_trainer",
  "employee_management",
  "employee_informations",
  "employee_skills_and_clearances",
  "employee_process",
  "employee_files",
  "employee_restrictions",
  "employee_comments",
  "employee_keywords",
  "employee_general_informations",
  "employee_team_history",
  "employee_performance_reviews",
  "employee_content_to_watch",
  "employee_contract",
  "workforce_planning",
  "workforce_planning_uimm",
  "birthday_date",
  "notes",
  "absences_reason",
  "approbations",
  "content_comment",
  "content_archived_management",
  "content_restriction",
  "kpis",
] as const;
