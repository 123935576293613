import { z } from "zod";

import { Id } from "@/shared/domain";

export const Employee = z.object({
  categories: z.array(z.object({ color_bg: z.string(), color_text: z.string(), name: z.string() })),
  contract_name: z.string().optional(),
  disabled: z.boolean().default(false),
  edit: z.boolean().optional(),
  factory_name: z.string(),
  first_name: z.string(),
  id: Id,
  last_name: z.string(),
  picture_url: z.string().nullable(),
  status: z.union([
    z.literal("active"),
    z.literal("archived"),
    z.literal("deleted"),
  ]).default("active"),
  team_name: z.string(),
});
export type Employee = z.infer<typeof Employee>;

export interface EmployeeItem {
  categories: { name: string }[];
  first_name: string;
  id: string;
  last_name: string;
  link_contracts: { contract: { contract_name: string; id: string } }[];
  picture_url: null | string;
  team: {
    factory: {
      factory_name: string;
    };
    id: string;
    team_name: string;
  };
}
