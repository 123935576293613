<template>
  <div :class="$style.container">
    <v-list
      class="pa-0"
      v-if="
        notification.type !== 'content-approbation' &&
        notification.type !== 'content-consultation' &&
        notification.type !== 'questionnaire-approbation'
      "
    >
      <v-divider></v-divider>
      <v-list-item class="pa-0">
        <v-avatar>
          <v-icon :size="20" v-if="notification.type === 'validation-training'">
            mdi-account-check-outline
          </v-icon>
          <v-icon :size="20" v-if="notification.type === 'update-team'">
            mdi-account-group-outline
          </v-icon>
          <v-icon :size="20" v-if="notification.type === 'fidelity'">
            mdi-medal-outline
          </v-icon>
          <v-icon :size="20" v-if="notification.type === 'birthday'">
            mdi-cake
          </v-icon>
          <v-icon
            :size="20"
            v-if="
              notification.type === 'clearance-expiration' ||
              notification.type === 'skill-expiration' ||
              notification.type === 'training-expiration' ||
              notification.type === 'contract-expiration'
            "
          >
            mdi-clock-alert-outline
          </v-icon>
        </v-avatar>

        <v-list-item-title
          :class="$style.text"
          @click="$emit('notification-click', notification.id)"
          v-if="notification.type === 'validation-training'"
        >
          <router-link
            :class="$style.link"
            :to="{
              name: 'employee',
              params: {id: notification.data.employee_id},
            }"
          >
            {{
              $t("notifications.training.validation", {
                employee: getEmployeeName(notification.data.employee_id),
                training: notification.data.skill_name,
              })
            }}
          </router-link>
        </v-list-item-title>
        <v-list-item-title
          :class="$style.text"
          @click="$emit('notification-click', notification.id)"
          v-if="notification.type === 'update-team'"
        >
          <router-link
            :class="$style.link"
            :to="{
              name: 'employee',
              params: {id: notification.data.employee_id},
            }"
          >
            {{
              $t("notifications.team_change", {
                employee: getEmployeeName(notification.data.employee_id),
                team_a: getTeamName(notification.data.old_team_id),
                team_b: getTeamName(notification.data.new_team_id),
              })
            }}
          </router-link>
        </v-list-item-title>
        <v-list-item-title
          :class="$style.text"
          @click="$emit('notification-click', notification.id)"
          v-if="notification.type === 'clearance-expiration'"
        >
          <router-link
            :class="$style.link"
            :to="{
              name: 'employee',
              params: {id: notification.data.employee_id},
            }"
          >
            {{
              $t("notifications.clearance", {
                employee: getEmployeeName(notification.data.employee_id),
                clearance_name: notification.data.clearance_name,
                when: formatDate(notification.data.expire_date),
              })
            }}
          </router-link>
        </v-list-item-title>
        <v-list-item-title
          :class="$style.text"
          @click="$emit('notification-click', notification.id)"
          v-if="notification.type === 'skill-expiration'"
        >
          <router-link
            :class="$style.link"
            :to="{
              name: 'employee',
              params: {id: notification.data.employee_id},
            }"
          >
            {{
              $t("notifications.skill", {
                employee: getEmployeeName(notification.data.employee_id),
                skill_name: notification.data.skill_name,
                when: formatDate(notification.data.expire_date),
              })
            }}
          </router-link>
        </v-list-item-title>
        <v-list-item-title
          :class="$style.text"
          @click="$emit('notification-click', notification.id)"
          v-if="notification.type === 'training-expiration'"
        >
          <router-link
            :class="$style.link"
            :to="{
              name: 'employee',
              params: {id: notification.data.employee_id},
            }"
          >
            {{
              $t("notifications.training.expiration", {
                employee: getEmployeeName(notification.data.employee_id),
                training: notification.data.training_name,
                when: formatDate(notification.data.expire_date),
              })
            }}
          </router-link>
        </v-list-item-title>
        <v-list-item-title
          :class="$style.text"
          @click="$emit('notification-click', notification.id)"
          v-if="notification.type === 'contract-expiration'"
        >
          <router-link
            :class="$style.link"
            :to="{
              name: 'employee',
              params: {id: notification.data.employee_id},
            }"
          >
            {{
              $t("notifications.contract", {
                employee: getEmployeeName(notification.data.employee_id),
                contract_name: getContractName(notification.data.contract_id),
                when: formatDate(notification.data.end_date_contract),
              })
            }}
          </router-link>
        </v-list-item-title>
        <v-list-item-title
          :class="$style.text"
          @click="$emit('notification-click', notification.id)"
          v-if="notification.type === 'birthday'"
        >
          <router-link
            :class="$style.link"
            :to="{
              name: 'employee',
              params: {id: notification.data.employee_id},
            }"
          >
            {{
              $t("notifications.birthday", {
                employee: getEmployeeName(notification.data.employee_id),
              })
            }}
          </router-link>
        </v-list-item-title>
      </v-list-item>
    </v-list>
    <NotificationItemDetails
      style="width: 100%"
      v-if="notification.type === 'questionnaire-approbation'"
      v-bind="getProps(notification)"
      @notification-click="$emit('notification-click', notification.id)"
    />
    <template v-if="isContentParameterActivatedOnCurrentSite">
      <NotificationItemDetails
        style="width: 100%"
        v-if="notification.type === 'content-approbation'"
        v-bind="getProps(notification)"
        @notification-click="$emit('notification-click', notification.id)"
      />
      <NotificationItemDetails
        style="width: 100%"
        v-if="notification.type === 'content-consultation'"
        v-bind="getProps(notification)"
        @notification-click="$emit('notification-click', notification.id)"
      />
    </template>

    <div
      :class="$style.markRead"
      @click="$emit('mark-read', notification.id)"
      aria-label="Mark notification read"
      title="Mark as read"
      v-if="!read"
    >
      <v-icon :size="18" color="grey_dark"> mdi-text-box-check-outline </v-icon>
    </div>
    <div
      :class="$style.remove"
      @click="$emit('remove-notification', notification.id)"
      aria-label="Remove notification"
      title="Clear notification"
      v-else
    >
      <v-icon :size="18" color="grey_dark"> mdi-trash-can-outline </v-icon>
    </div>
  </div>
</template>

<script lang="ts">
import { mapState } from "pinia";
import Vue, { type PropType } from "vue";

import type {
  IContract,
  IEmployee,
  INotification,
  ITeam,
} from "@/tscript/interfaces";
import type { ContractID, EmployeeID, TeamID } from "@/tscript/mercateam";

import {
  useContractGlobalStore,
  useEmployeeGlobalStore,
  useTeamGlobalStore,
} from "@/pinia/collections";
import { useAuthentificationStore } from "@/pinia/user";
import { DateUtils } from "@/tscript/utils/date";

import NotificationItemDetails from "./NotificationItemDetails.vue";

export default Vue.extend({
  components: { NotificationItemDetails },
  computed: {
    ...mapState(useAuthentificationStore, {
      userLanguage: "userLanguage",
    }),
    ...mapState(useEmployeeGlobalStore, {
      employeeDictionnary: "dictionary",
    }),
    ...mapState(useAuthentificationStore, {
      activeSiteFull: "active_site_full",
    }),
    ...mapState(useTeamGlobalStore, { teamsList: "actives" }),
    ...mapState(useContractGlobalStore, {
      contractsList: "actives",
    }),
    isContentParameterActivatedOnCurrentSite(): boolean {
      return !!this.activeSiteFull?.settings_content_feature;
    },
  },
  data: () => ({ DateUtils }),
  methods: {
    formatDate(date: any): string {
      return typeof date === "string"
        ? date
        : date.toDate().toLocaleDateString("fr-FR");
    },
    getContractName(contractId: ContractID) {
      const findContract: IContract | undefined = this.contractsList.find(
        ({ id }) => id === contractId,
      );
      return findContract?.contract_name;
    },
    getEmployeeName(employeeId: EmployeeID): null | string {
      if (!this.employeeDictionnary) return null;
      const findEmployee: IEmployee | undefined =
        this.employeeDictionnary[employeeId];
      if (!findEmployee) return null;
      return `${findEmployee.first_name} ${findEmployee.last_name}`;
    },
    getProps(notification: INotification) {
      switch (notification.type) {
        case "questionnaire-approbation":
          return {
            link: {
              label: this.$t("questionnaire.mail.approbators_subject"),
              route: {
                name: "Approbations",
                params: { id: notification.data.questionnaire.id },
              },
            },
            sinceCurrentDate: DateUtils.formatDistanceAndTranslation(
              new Date(notification.data.questionnaire.created_at),
              this.userLanguage,
            ),
            title: `${notification.data.questionnaire.title} - ${notification.data.questionnaire.revision_number}`,
          };
        case "content-approbation":
          return {
            link: {
              label: this.$t("content.content_to_approve"),
              route: {
                name: "ContentDisplay",
                params: { id: notification.data.content.id },
                query: { fromPage: "notifications" },
              },
            },
            sinceCurrentDate: DateUtils.formatDistanceAndTranslation(
              DateUtils.convertToDate(notification.data.content.created_at),
              this.userLanguage,
            ),
            title: `${notification.data.content.title} - ${notification.data.content.revision_number}`,
          };
        case "content-consultation":
          return {
            link: {
              label: this.$tc("content.content_to_consult", 1),
              route: {
                name: "ContentDisplay",
                params: { id: notification.data.content.id },
              },
            },
            sinceCurrentDate: DateUtils.formatDistanceAndTranslation(
              DateUtils.convertToDate(notification.data.content.created_at),
              this.userLanguage,
            ),
            title: `${notification.data.content.title} - ${notification.data.content.revision_number}`,
          };
        default:
          break;
      }
    },
    getTeamName(teamId: TeamID) {
      const teamList: ITeam[] = [...this.teamsList];
      const findTeam: ITeam | undefined = teamList.find(
        ({ id }) => id === teamId,
      );
      return findTeam?.team_name;
    },
  },
  name: "NotificationItem",
  props: {
    notification: Object as PropType<INotification>,
    read: {
      default: false,
      type: Boolean as PropType<boolean>,
    },
  },
});
</script>

<style lang="scss" module>
@use "../../../scss/mixins" as mx;

.container {
  align-items: center;
  background-color: white;
  display: flex;
  position: relative;
  width: 100%;
  &:hover {
    .markRead,
    .remove {
      opacity: 1;
    }
  }
}
.text {
  @include mx.line-clamp(2);
  font-size: 0.85rem;
  line-height: 130% !important;
  white-space: normal;
  padding-right: 18px;
  width: 300px;
  &:hover {
    text-decoration: underline;
  }
}
.link {
  color: initial !important;
}
.icon {
  margin-left: 1rem;
  margin-right: 0.5rem;
}
.markRead,
.remove {
  background-color: transparent !important;
  border-radius: 0;
  min-width: 0 !important;
  opacity: 0;
  padding: 0 !important;
  position: absolute !important;
  right: 10px;
  top: 10px;
  cursor: pointer;
  &:hover::before {
    background-color: transparent !important;
  }
}
</style>
