import { connectAuthEmulator, getAuth } from "firebase/auth";
import Vue from "vue";
import VueRouter from "vue-router";

import { config } from "@/config";
import contentRoutes from "@/modules/contents/routes";
import performanceReviewsRoutes from "@/modules/performance-review-v2/routes";
import childrenPerformanceReview from "@/modules/performance-review/routes";
import usePermissions from "@/modules/rights/composables/use-permissions";
import { ERole, ESectionName } from "@/modules/rights/types";
import childrenWorkforcePlanning from "@/modules/workforce-planning/routes";
import { useContentSearchStore } from "@/pinia/features/contentSearch";
import { useAuthentificationStore } from "@/pinia/user";

import {
  childrenAdmin,
  childrenAdvancedSearch,
  childrenContent,
  childrenDashboard,
  childrenFactory,
  childrenKpisDashboard,
  childrenLibrary,
  childrenMonitoring,
  childrenQuestionnaire,
  childrenSettings,
  childrenTeams,
  childrenTrainingSummary,
  childrenTrainingv3,
} from "./children";

Vue.use(VueRouter);

const routes = [
  {
    component: () => import("@/views/Home.vue"),
    meta: {
      requiresAuth: true,
    },
    name: "home",
    path: "/",
  },
  {
    component: () => import("@/views/ContractAnalysis.vue"),
    meta: {
      requiresAuth: true,
      userRightsSection: ESectionName.Enum.employee_contract,
    },
    name: "contracts-analysis",
    path: "/contracts-analysis",
  },
  {
    component: () => import("@/views/HomeV2.vue"),
    meta: {
      requiresAuth: true,
    },
    name: "homeV2",
    path: "/home-v2",
  },
  {
    children: childrenSettings,
    component: () => import("@/views/Settings.vue"),
    meta: {
      requiresAuth: true,
      userRightsSection: ESectionName.Enum.parameters,
    },
    path: "/settings",
  },
  {
    component: () => import("@/modules/rights/views/Rights.vue"),
    meta: {
      requiresAuth: true,
    },
    path: "/rights",
  },
  {
    children: childrenAdmin,
    component: () => import("@/views/Admin.vue"),
    meta: {
      requiresAdmin: true,
      requiresAuth: true,
      userRightsSection: ESectionName.Enum.administration,
    },
    path: "/admin",
  },
  {
    children: childrenFactory,
    component: () => import("@/views/Factory.vue"),
    meta: {
      requiresAuth: true,
    },
    name: "factory",
    path: "/factory/:id",
    props: true,
  },
  {
    children: childrenTeams,
    component: () => import("@/views/Team.vue"),
    meta: {
      requiresAuth: true,
    },
    path: "/team/:id",
    props: true,
  },
  {
    children: childrenLibrary,
    component: () => import("@/views/Library.vue"),
    meta: {
      requiresAdmin: true,
      requiresAuth: true,
      userRightsSection: ESectionName.Enum.library,
    },
    name: "library",
    path: "/library",
  },
  {
    component: () => import("@/views/export/Exports.vue"),
    meta: {
      requiresAdmin: true,
      requiresAuth: true,
      userRightsSection: ESectionName.Enum.export,
    },
    name: "exports",
    path: "/exports",
  },
  {
    component: () => import("@/views/import/Imports.vue"),
    meta: {
      requiresAdmin: true,
      requiresAuth: true,
      userRightsSection: ESectionName.Enum.import,
    },
    name: "imports",
    path: "/imports",
  },
  {
    children: childrenMonitoring,
    component: () => import("@/views/Monitoring.vue"),
    meta: {
      requiresAdmin: true,
      requiresAuth: true,
    },
    name: "Monitoring",
    path: "/monitoring",
  },
  {
    component: () =>
      import(
        "@/modules/monitoring/analysis-goals/views/AnalysisGoalsDashboard.vue"
      ),
    meta: {
      requiresAuth: true,
      userRightsSection: ESectionName.Enum.monitoring_goals,
    },
    name: "monitoringv2",
    path: "/monitoringv2",
  },
  {
    component: () =>
      import(
        "@/modules/monitoring/analysis-goals/views/analysisGoalsDisplay/AnalysisGoalsDisplay.vue"
      ),
    meta: {
      requiresAuth: true,
      userRightsSection: ESectionName.Enum.monitoring_goals,
    },
    name: "analysis-goals-display",
    path: "/monitoringv2/:id?",
  },
  {
    component: () =>
      import("@/modules/monitoring/analysis-goals/views/AnalysisGoalsForm.vue"),
    meta: {
      requiresAuth: true,
      userRightsSection: ESectionName.Enum.monitoring_goals,
    },
    name: "analysis-goals-form",
    path: "/monitoringv2/form/:id",
  },
  {
    children: childrenContent,
    component: () => import("@/views/content/Content.vue"),
    meta: {
      requiresAdmin: true,
      requiresAuth: true,
      userRightsSection: ESectionName.Enum.content_management,
    },
    name: "contentOverview",
    path: "/content",
    redirect: "content/overview",
  },
  {
    component: () => import("@/views/content/ContentRevision.vue"),
    meta: {
      requiresAdmin: true,
      requiresAuth: true,
    },
    name: "ContentRevision",
    path: "/content/revision/:id",
  },
  {
    component: () => import("@/views/Login.vue"),
    meta: { hideNavigation: true, isPublic: true },
    name: "login",
    path: "/login",
  },
  {
    component: () => import("@/modules/legal/views/Conditions.vue"),
    meta: { hideNavigation: true, isPublic: true },
    name: "terms",
    path: "/terms",
  },
  {
    component: () => import("@/views/Password.vue"),
    meta: { hideNavigation: true },
    name: "password",
    path: "/password/:action",
  },
  {
    component: () => import("@/views/Employee.vue"),
    meta: {
      requiresAuth: true,
      userRightsSection: ESectionName.Enum.employee_informations,
    },
    name: "employee",
    path: "/employee/:id",
  },
  {
    component: () => import("@/views/employee/NewEmployee.vue"),
    meta: {
      requiresAuth: true,
      userRightsSection: ESectionName.Enum.employee_management,
    },
    name: "new_employee",
    path: "/new/employee",
  },
  {
    component: () => import("@/views/SimpleSearch.vue"),
    meta: {
      requiresAuth: true,
    },
    name: "SimpleSearchView",
    path: "/search/:id",
  },
  {
    children: childrenAdvancedSearch,
    component: () => import("@/views/AdvancedSearch.vue"),
    meta: {
      requiresAuth: true,
      userRightsSection: ESectionName.Enum.advanced_search,
    },
    path: "/advanced-search",
  },
  {
    component: () => import("@/views/profile/ContentApprobation.vue"),
    meta: {
      requiresAuth: true,
    },
    name: "ContentApprobation",
    path: "/profile/content/:id",
  },
  {
    component: () => import("@/views/profile/approbations/MyApprobations.vue"),
    meta: {
      requiresAuth: true,
    },
    name: "Approbations",
    path: "/profile/approbations/:id",
  },
  {
    component: () => import("@/views/user/Profile.vue"),
    meta: {
      requiresAuth: true,
    },
    name: "profile",
    path: "/profile/:id",
  },
  {
    children: childrenTrainingv3,
    component: () => import("@/views/trainingv3/TrainingView.vue"),
    meta: {
      requiresAuth: true,
    },
    name: "trainingV3",
    path: "/trainingv3",
  },
  {
    children: childrenTrainingSummary,
    component: () => import("@/views/trainingv3/TrainingSummary"),
    meta: {
      requiresAuth: true,
      userRightsSection: ESectionName.Enum.training_follow_validate,
    },
    name: "trainingSummaryV3",
    path: "/training-summary-v3/:idTraining",
  },
  {
    children: childrenQuestionnaire,
    component: () =>
      import(
        "@/views/questionnaire/questionnaireCreation/QuestionnaireCreation"
      ),
    meta: {
      hideFooter: true,
      hideNavigation: true,
      requiresAuth: true,
    },
    name: "questionnaireCreation",
    path: "/questionnaire/create/:id",
  },
  {
    component: () =>
      import(
        "@/views/questionnaire/questionnaireDisplay/QuestionnaireDisplayEvaluation"
      ),
    meta: {
      requiresAuth: true,
    },
    name: "questionnaireDisplayConsultation",
    path: "/questionnaire/display/:id",
  },
  {
    component: () =>
      import("@/modules/trainings/views/CompareQuestionnaireVersions.vue"),
    meta: {
      requiresAuth: true,
    },
    name: "compareQuestionnaireVersions",
    path: "/questionnaire/compare/:id",
  },
  {
    component: () =>
      import(
        "@/views/questionnaire/questionnaireDisplay/QuestionnaireDisplayEvaluation"
      ),
    meta: {
      requiresAuth: true,
    },
    name: "questionnaireDisplayEvaluation",
    path: "/questionnaire/evaluation/:id",
  },
  {
    children: childrenDashboard,
    component: () => import("@/views/dashboard/DashboardInformationsView.vue"),
    meta: {
      requiresAuth: true,
    },
    name: "DashboardInformations",
    path: "/informations",
  },
  {
    component: () => import("@/views/factory/AbsencesManager.vue"),
    meta: {
      requiresAuth: true,
      userRightsSection: ESectionName.Enum.team_absences,
    },
    name: "absencesManager",
    path: "/absences-manager",
  },
  {
    component: () => import("@/components/team/TeamMultiAdmin.vue"),
    meta: {
      requiresAuth: true,
    },
    name: "import-global",
    path: "/import/:target",
  },
  {
    component: () => import("@/views/Logout.vue"),
    meta: { hideNavigation: true },
    name: "logout",
    path: "/logout",
  },
  {
    children: childrenPerformanceReview,
    component: () =>
      import("@/modules/performance-review/views/PerformanceReviewRoot.vue"),
    meta: { hideNavigation: true },
    path: "/performance-reviews",
  },
  {
    children: childrenWorkforcePlanning,
    component: () =>
      import("@/modules/workforce-planning/views/WorkforcePlanningRoot.vue"),
    meta: {
      requiresAuth: true,
      userRightsSection: ESectionName.Enum.workforce_planning,
    },
    path: "/workforce-planning",
  },
  {
    children: childrenKpisDashboard,
    component: () => import("@/modules/kpis-dashboard/views/KpisDashboard.vue"),
    meta: {
      requiresAuth: true,
      userRightsSection: ESectionName.Enum.kpis,
    },
    path: "/kpis-dashboard",
  },
  ...contentRoutes,
  {
    component: () => import("@/views/PageNotFound.vue"),
    path: "*",
  },
  ...performanceReviewsRoutes,
];

const router = new VueRouter({
  base: import.meta.env.BASE_URL,
  mode: "history",
  routes,
});

router.beforeResolve((to, from, next) => {
  if (to.meta?.isPublic) {
    next();
  }
  if (to.name === "home") {
    if (useAuthentificationStore().data?.role === ERole.Enum.CUSTOMER_SUCCESS) {
      next({ path: "/admin/customer-sucess" });
    }
    if (useAuthentificationStore().data?.role === ERole.Enum.ADMIN) {
      next({ path: "/admin" });
    }
    next();
  }

  if (to.name === "UsersAdmin") {
    if (useAuthentificationStore().data?.role === ERole.Enum.CUSTOMER_SUCCESS) {
      next({ path: "/admin/customer-sucess" });
    }
    next();
  }
  if (
    from?.matched?.length &&
    from.matched[0]?.path === "/content" &&
    to?.matched?.length &&
    to.matched[0]?.path !== "/content"
  ) {
    localStorage.removeItem("ContentDetailPagination");
  }

  const userRightGuards = () => {
    const permissionsDictionary = AuthentificationStore.permissions;
    if (!permissionsDictionary) throw new Error("No permissionsDictionary");
    let allowed = true;
    for (let i = to.matched.length - 1; i >= 0; i--) {
      const rec = to.matched[i];
      if (!rec.meta.userRightsSection) continue;
      allowed = usePermissions(rec.meta.userRightsSection)?.readAllowed?.value;
      break;
    }
    if (!allowed) {
      next({
        name: "home",
      });
      throw new Error(
        "You do not have the user rights to access to this section",
      );
    }
    next();
  };

  const AuthentificationStore = useAuthentificationStore();
  if (AuthentificationStore.data) {
    userRightGuards();
  } else {
    const auth = getAuth();

    if (config.useLocalAuthentification) {
      if (!config.portLocalAuthentification)
        throw new Error(
          "portLocalAuthentification need to be defined if you are using useLocalAuthentification. See @/config/index.ts",
        );
      connectAuthEmulator(auth, `${config.portLocalAuthentification}`);
    }
    auth.onAuthStateChanged((user) => {
      (async () => {
        if (user) {
          const uid = user.uid;
          if (uid) {
            await AuthentificationStore.setUserAuthenticated(uid);
            userRightGuards();
          }
        } else if (to.meta?.isPublic) {
          next();
        } else {
          next({
            name: "login",
          });
        }
      })();
    });
  }

  if (
    (to.matched[0] && to.matched[0].path === "/content") ||
    (to.matched[1] && to.matched[1].path === "/team/:id/content")
  ) {
    if (JSON.stringify(from.query) !== JSON.stringify(to.query)) {
      useContentSearchStore().currentRouteQuery = to.query;
    }
  }
  if (to.matched[1] && to.matched[1].path === "/content/view/:id") {
    useContentSearchStore().currentContentViewedID = to.params.id;
  }

  if (to.name !== "analysis-goals-form") {
    localStorage.removeItem("analysisGoals-form");
  }
});

export default router;
