import { type Dictionary, orderBy } from "lodash";

import { usePlanningDisplayStore } from "@/pinia/features/planning-display";

import type {
  AbsenceResult,
  CellResult,
  IAlingCell,
  ShiftResult,
} from "./planningBuilder";

type IPlanningDataDictionary = Record<
  string,
  Record<number, (CellResult | IAlingCell)[]>
>;

type IMonthEmployeeShifts = Record<
  string,
  {
    name: string;
    number: number;
    positionsData: Record<
      string,
      {
        position_id: string;
        shift_id: string;
      }
    >;
    positionsIds: string[];
  }
>;
type IEmployeeMonthPlanning = Record<
  string,
  {
    monthEmployeeShifts: IMonthEmployeeShifts;
  }
>;

type IAbsenceDataDictionary = Record<string, Record<number, AbsenceResult[]>>;

type IShiftDataDictionary = Record<string, Record<number, ShiftResult[]>>;

export const planningAligmentMonth = (
  cells: CellResult[],
  absences: AbsenceResult[],
  shifts: ShiftResult[],
): {
  allDataByEmloyeeAbsence: IAbsenceDataDictionary;
  allDataByEmployee: IPlanningDataDictionary;
  allShiftByEmployee: IShiftDataDictionary;
  compactPlanningByEmployeeLine: Dictionary<number>;
  compactPlanningRow: Dictionary<number>;
  statPlanningByEmployeeLine: Dictionary<number>;
  statPlanningByEmployeeRow: Dictionary<number>;
} => {
  const allDataByEmployee: IPlanningDataDictionary = {};
  const allShiftByEmployee: IShiftDataDictionary = {};
  const employeeMonthPlanning: IEmployeeMonthPlanning = {};
  const statPlanningByEmployeeLine: Dictionary<number> = {};
  const statPlanningByEmployeeRow: Dictionary<number> = {};
  const compactPlanningByEmployeeLine: Dictionary<number> = {};
  const compactPlanningRow: Dictionary<number> = {};

  const PlanningDisplayStore = usePlanningDisplayStore();

  for (const cell of cells) {
    if (statPlanningByEmployeeLine[cell.employee_id]) {
      statPlanningByEmployeeLine[cell.employee_id] +=
        cell.time.totalTimeNumberInMinutes;
    } else {
      statPlanningByEmployeeLine[cell.employee_id] =
        cell.time.totalTimeNumberInMinutes;
    }

    if (statPlanningByEmployeeRow[cell.day]) {
      statPlanningByEmployeeRow[cell.day] += cell.time.totalTimeNumberInMinutes;
    } else {
      statPlanningByEmployeeRow[cell.day] = cell.time.totalTimeNumberInMinutes;
    }

    if (cell.isTomorrow) continue;

    if (compactPlanningByEmployeeLine[cell.employee_id]) {
      compactPlanningByEmployeeLine[cell.employee_id] +=
        cell.time.totalTimeNumberInMinutes;
    } else {
      compactPlanningByEmployeeLine[cell.employee_id] =
        cell.time.totalTimeNumberInMinutes;
    }

    if (compactPlanningRow[cell.day]) {
      compactPlanningRow[cell.day] += cell.time.totalTimeNumberInMinutes;
    } else {
      compactPlanningRow[cell.day] = cell.time.totalTimeNumberInMinutes;
    }

    const shift_name = PlanningDisplayStore.isAlignEmployees
      ? "isAlignByEmployees"
      : cell.shift.name.toLocaleLowerCase();

    if (employeeMonthPlanning[cell.employee_id]) {
      if (
        employeeMonthPlanning[cell.employee_id].monthEmployeeShifts[shift_name]
      ) {
        employeeMonthPlanning[cell.employee_id].monthEmployeeShifts[
          shift_name
        ].positionsIds.push(cell.position_id);
        employeeMonthPlanning[cell.employee_id].monthEmployeeShifts[
          shift_name
        ].positionsData[cell.position_id] = {
          position_id: cell.position_id,
          shift_id: cell.shift_id,
        };
        employeeMonthPlanning[cell.employee_id].monthEmployeeShifts[shift_name]
          .number++;
      } else {
        employeeMonthPlanning[cell.employee_id].monthEmployeeShifts[
          shift_name
        ] = {
          name: shift_name,
          number: 1,
          positionsData: {
            [cell.position_id]: {
              position_id: cell.position_id,
              shift_id: cell.shift_id,
            },
          },
          positionsIds: [cell.position_id],
        };
      }
    } else {
      employeeMonthPlanning[cell.employee_id] = {
        monthEmployeeShifts: {
          [shift_name]: {
            name: shift_name,
            number: 1,
            positionsData: {
              [cell.position_id]: {
                position_id: cell.position_id,
                shift_id: cell.shift_id,
              },
            },
            positionsIds: [cell.position_id],
          },
        },
      };
    }

    if (allDataByEmployee[cell.employee_id]) {
      if (allDataByEmployee[cell.employee_id][cell.day]) {
        if (employeeMonthPlanning[cell.employee_id][cell.day][shift_name]) {
          const dayShift =
            employeeMonthPlanning[cell.employee_id][cell.day][shift_name];
          dayShift.number++;

          if (dayShift.positionsIds) {
            dayShift.positionsIds.push(cell.position_id);
            dayShift.positionsData[cell.position_id] = {
              position_id: cell.position_id,
              shift_id: cell.shift_id,
            };
          } else {
            dayShift.positionsIds = [cell.position_id];
            dayShift.positionsData = {
              [cell.position_id]: {
                position_id: cell.position_id,
                shift_id: cell.shift_id,
              },
            };
          }
        } else {
          employeeMonthPlanning[cell.employee_id][cell.day][shift_name] = {
            name: shift_name,
            number: 1,
            positionsData: {
              [cell.position_id]: {
                position_id: cell.position_id,
                shift_id: cell.shift_id,
              },
            },
            positionsIds: [cell.position_id],
          };
        }

        allDataByEmployee[cell.employee_id][cell.day].push(cell);
      } else {
        employeeMonthPlanning[cell.employee_id][cell.day] = {
          [shift_name]: {
            name: shift_name,
            number: 1,
            positionsData: {
              [cell.position_id]: {
                position_id: cell.position_id,
                shift_id: cell.shift_id,
              },
            },

            positionsIds: [cell.position_id],
          },
        };
        allDataByEmployee[cell.employee_id][cell.day] = [cell];
      }
    } else {
      employeeMonthPlanning[cell.employee_id] = {
        [cell.day]: {
          [shift_name]: {
            name: shift_name,
            number: 1,
            positionsData: {
              [cell.position_id]: {
                position_id: cell.position_id,
                shift_id: cell.shift_id,
              },
            },
            positionsIds: [cell.position_id],
          },
        },
        monthEmployeeShifts: {
          [shift_name]: {
            name: shift_name,
            number: 1,
            positionsData: {
              [cell.position_id]: {
                position_id: cell.position_id,
                shift_id: cell.shift_id,
              },
            },
            positionsIds: [cell.position_id],
          },
        },
      };
      allDataByEmployee[cell.employee_id] = {
        [cell.day]: [cell],
      };
    }
    // END ALIGNEMENT EMPLOYEE VIEW
  }

  for (const employeeID in allDataByEmployee) {
    const dataByEmployee = allDataByEmployee[employeeID];
    for (const dayOfTheMonth in dataByEmployee) {
      const dataByEmployeeAndDay = dataByEmployee[dayOfTheMonth];
      allDataByEmployee[employeeID][dayOfTheMonth] = orderBy(
        dataByEmployeeAndDay,
        ["shift_id", "position_id"],
      );
    }
  }
  // END Loop through the employeess dictionary

  const allDataByEmloyeeAbsence: IAbsenceDataDictionary = {};

  for (const absence of absences) {
    if (allDataByEmloyeeAbsence[absence.employee_id]) {
      if (allDataByEmloyeeAbsence[absence.employee_id][absence.day]) {
        allDataByEmloyeeAbsence[absence.employee_id][absence.day].push(absence);
      } else {
        allDataByEmloyeeAbsence[absence.employee_id][absence.day] = [];
        allDataByEmloyeeAbsence[absence.employee_id][absence.day].push(absence);
      }
    } else {
      allDataByEmloyeeAbsence[absence.employee_id] = {};
      allDataByEmloyeeAbsence[absence.employee_id][absence.day] = [];
      allDataByEmloyeeAbsence[absence.employee_id][absence.day].push(absence);
    }
  }

  for (const shift of shifts) {
    if (allShiftByEmployee[shift.employee_id]) {
      if (allShiftByEmployee[shift.employee_id][shift.day]) {
        allShiftByEmployee[shift.employee_id][shift.day].push(shift);
      } else {
        allShiftByEmployee[shift.employee_id][shift.day] = [];
        allShiftByEmployee[shift.employee_id][shift.day].push(shift);
      }
    } else {
      allShiftByEmployee[shift.employee_id] = {};
      allShiftByEmployee[shift.employee_id][shift.day] = [];
      allShiftByEmployee[shift.employee_id][shift.day].push(shift);
    }
  }

  return {
    allDataByEmloyeeAbsence,
    allDataByEmployee,
    allShiftByEmployee,
    compactPlanningByEmployeeLine,
    compactPlanningRow,
    statPlanningByEmployeeLine,
    statPlanningByEmployeeRow,
  };
};
