import type { TranslateResult } from "vue-i18n";

import { defineStore } from "pinia";

import i18n from "@/i18n";
import { updateSwitcher } from "@/tscript/apollo/mutation/update";
import ClientHelper from "@/tscript/database/ClientHelper";
import {
  EResolverName,
  type IUndoActions,
  type IUndoElement,
  undoActions,
  undoGeneralActions,
} from "@/tscript/interfaces";

import { usePlanningStore } from "./features/planning";

export const useUndoActionStore = defineStore({
  actions: {
    async undo() {
      try {
        if (!this.undoArray || this.undoArray.length <= 0) return;
        this.disableUndo = true;
        const lastAction = this.undoArray.pop();
        if (!lastAction) throw new Error("No last action found in undo()");

        for (const collection in lastAction.actions) {
          const updateManyPayload: any = lastAction.actions[collection];
          if (collection === "link_employee_planning") {
            for (const currentData of updateManyPayload) {
              const newData = {
                status: currentData.status === "active" ? "active" : "deleted",
              };
              await updateSwitcher(EResolverName.cellResult, {
                data: newData,
                where: { id: currentData.id },
              });
            }
          } else if (collection === "link_employee_shifts") {
            for (const currentData of updateManyPayload) {
              const newData = {
                status: currentData.status === "active" ? "active" : "deleted",
              };
              await updateSwitcher(EResolverName.cellShift, {
                data: newData,
                where: { id: currentData.id },
              });
            }
          } else if (collection === "link_employee_time_absences") {
            for (const currentData of updateManyPayload) {
              const newData = {
                status: currentData.status === "active" ? "active" : "deleted",
              };
              await updateSwitcher(EResolverName.cellAbsence, {
                data: newData,
                where: { id: currentData.id },
              });
            }
          } else {
            const client = new ClientHelper(collection);
            await client.updateMany(updateManyPayload);
          }
        }
        setTimeout(() => {
          this.disableUndo = false;
        }, 2000);
      } catch (error) {
        console.error("Error in undo:", error);
      } finally {
        usePlanningStore().refetch = Date.now();
      }
    },
    updateUndoArray(newUndoElement: IUndoElement | IUndoElement[]) {
      const isArray = Array.isArray(newUndoElement);
      const firstUndoElement = isArray ? newUndoElement[0] : newUndoElement;
      if (!firstUndoElement.lastActionSlug) return;
      this.disableUndo = true;

      if (this.undoArray.length >= 10) this.undoArray.shift();

      const collection = firstUndoElement.collection;
      const existingUndoActionIndex = this.undoArray.findIndex(
        (undoAction) =>
          undoAction.shiftActionSlug &&
          undoAction.shiftActionSlug === firstUndoElement.lastActionSlug,
      );

      const updatePayload: any = Array.isArray(newUndoElement)
        ? newUndoElement.map((undoElement) => {
          return {
            id: undoElement.id,
            status:
                undoElement.action === undoActions.create
                  ? "deleted"
                  : "active",
          };
        })
        : [
            {
              id: newUndoElement.id,
              status:
                newUndoElement.action === undoActions.create
                  ? "deleted"
                  : "active",
            },
          ];

      const actionAlreadyExists = existingUndoActionIndex >= 0;
      if (actionAlreadyExists) {
        if (this.undoArray[existingUndoActionIndex].actions[collection]) {
          this.undoArray[existingUndoActionIndex].actions[collection].push(
            ...updatePayload,
          );
        } else {
          this.undoArray[existingUndoActionIndex].actions[collection] = [
            ...updatePayload,
          ];
        }
      } else {
        const undoAction: any = {
          actions: {
            [collection]: updatePayload,
          },
          shiftActionSlug: firstUndoElement.lastActionSlug
            ? firstUndoElement.lastActionSlug
            : null,
        };

        const originalAction =
          firstUndoElement.originalAction ||
          firstUndoElement.originalAction === 0
            ? firstUndoElement.originalAction
            : null;

        if (originalAction || originalAction === 0)
          undoAction.originalAction = originalAction;

        this.undoArray.push(undoAction);
      }
    },
  },
  getters: {
    getLastActionToUndo(): TranslateResult {
      if (this.undoArray.length <= 0) return i18n.t("undo.nothing-to-delete");
      const lastAction = this.undoArray[this.undoArray.length - 1];
      switch (lastAction.originalAction) {
        case undoGeneralActions.absenceCreation:
          return i18n.t("undo.cancel-last-absence-creation");

        case undoGeneralActions.absenceDeletion:
          return i18n.t("undo.cancel-last-absence-deletion");

        case undoGeneralActions.positionsCreation:
          return i18n.t("undo.cancel-last-position-creation");

        case undoGeneralActions.positionsDeletion:
          return i18n.t("undo.cancel-last-position-deletion");

        case undoGeneralActions.shiftCreation:
          return i18n.t("undo.cancel-last-shift-creation");

        case undoGeneralActions.shiftDeletion:
          return i18n.t("undo.cancel-last-shift-deletion");

        default:
          return i18n.t("undo.cancel");
      }
    },
  },
  id: "useUndoAction",
  state: () => ({
    disableUndo: false as boolean,
    undoArray: [] as IUndoActions[],
  }),
});
